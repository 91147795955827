//  TODO: move this stuff to web

export namespace TranslocoApi {
  export enum Locale {
    EN = 'EN',
    DE = 'DE',
    HR = 'HR',
    RU = 'RU',
    RO = 'RO',
    PL = 'PL',
    TR = 'TR',
    FR = 'FR',
    IT = 'IT',
    ES = 'ES',
    UK = 'UK',
    CS = 'CS',
    SK = 'SK',
  }

  export enum LanguageLevel {
    BEGINNER = 'BEGINNER',
    INTERMEDIATE = 'INTERMEDIATE',
    ADVANCED = 'ADVANCED',
    FLUENT = 'FLUENT',
    NATIVE = 'NATIVE',
  }

  export interface Language {
    language: OfficialLanguage | undefined;
    level: LanguageLevel | undefined;
  }

  //  FIXME: move to web scope
  export function getEmoji(language: Locale): string {
    switch (language) {
      case TranslocoApi.Locale.EN:
        return '🇬🇧';
      case TranslocoApi.Locale.DE:
        return '🇩🇪';
      case TranslocoApi.Locale.HR:
        return '🇭🇷';
      case TranslocoApi.Locale.ES:
        return '🇪🇸';
      case TranslocoApi.Locale.RO:
        return '🇷🇴';
      case TranslocoApi.Locale.PL:
        return '🇵🇱';
      case TranslocoApi.Locale.TR:
        return '🇹🇷';
      case TranslocoApi.Locale.FR:
        return '🇫🇷';
      case TranslocoApi.Locale.IT:
        return '🇮🇹';
      case TranslocoApi.Locale.RU:
        return '🇷🇺';
      case TranslocoApi.Locale.UK:
        return '🇺🇦';
      case TranslocoApi.Locale.CS:
        return '🇨🇿';
      case TranslocoApi.Locale.SK:
        return '🇸🇰';
    }
  }

  //  FIXME: move to web scope
  export function getLanguageString(language: Locale): string {
    switch (language) {
      case TranslocoApi.Locale.EN:
        return 'English';
      case TranslocoApi.Locale.DE:
        return 'German';
      case TranslocoApi.Locale.HR:
        return 'Croatian';
      case TranslocoApi.Locale.ES:
        return 'Spanish';
      case TranslocoApi.Locale.RO:
        return 'Romanian';
      case TranslocoApi.Locale.PL:
        return 'Polish';
      case TranslocoApi.Locale.TR:
        return 'Turkish';
      case TranslocoApi.Locale.FR:
        return 'French';
      case TranslocoApi.Locale.IT:
        return 'Italian';
      case TranslocoApi.Locale.RU:
        return 'Russian';
      case TranslocoApi.Locale.SK:
        return 'Slovak';
      case TranslocoApi.Locale.CS:
        return 'Czech';
      case TranslocoApi.Locale.UK:
        return 'Ukrainian';
    }
  }

  export function getLanguageTranslocoString(language: Locale): string {
    switch (language) {
      case TranslocoApi.Locale.EN:
        return 'shared.transloco.languages.english';
      case TranslocoApi.Locale.DE:
        return 'shared.transloco.languages.german';
      case TranslocoApi.Locale.HR:
        return 'shared.transloco.languages.croatian';
      case TranslocoApi.Locale.ES:
        return 'shared.transloco.languages.spanish';
      case TranslocoApi.Locale.RO:
        return 'shared.transloco.languages.romanian';
      case TranslocoApi.Locale.PL:
        return 'shared.transloco.languages.polish';
      case TranslocoApi.Locale.TR:
        return 'shared.transloco.languages.turkish';
      case TranslocoApi.Locale.FR:
        return 'shared.transloco.languages.french';
      case TranslocoApi.Locale.IT:
        return 'shared.transloco.languages.italian';
      case TranslocoApi.Locale.RU:
        return 'shared.transloco.languages.russian';
      case TranslocoApi.Locale.SK:
        return 'shared.transloco.languages.slovak';
      case TranslocoApi.Locale.CS:
        return 'shared.transloco.languages.czech';
      case TranslocoApi.Locale.UK:
        return 'shared.transloco.languages.ukrainian';
    }
  }

  export enum OfficialLanguage {
    CATALAN_VALENCIAN = 'Catalan, Valencian',
    PERSIAN = 'Persian',
    ENGLISH = 'English',
    ALBANIAN = 'Albanian',
    ARMENIAN = 'Armenian',
    PORTUGUESE = 'Portuguese',
    SPANISH_CASTILIAN = 'Spanish, Castilian',
    GERMAN = 'German',
    DUTCH_FLEMISH = 'Dutch, Flemish',
    SWEDISH = 'Swedish',
    AZERBAIJANI = 'Azerbaijani',
    BOSNIAN = 'Bosnian',
    BENGALI = 'Bengali',
    FRENCH = 'French',
    BULGARIAN = 'Bulgarian',
    ARABIC = 'Arabic',
    MALAY = 'Malay',
    DZONGKHA = 'Dzongkha',
    NORWEGIAN = 'Norwegian',
    BELARUSIAN = 'Belarusian',
    GREEK_MODERN = 'Greek, Modern (1453-)',
    DANISH = 'Danish',
    ESTONIAN = 'Estonian',
    TIGRINYA = 'Tigrinya',
    AMHARIC = 'Amharic',
    FINNISH = 'Finnish',
    GEORGIAN = 'Georgian',
    KALAALLISUT_GREENLANDIC = 'Kalaallisut, Greenlandic',
    CROATIAN = 'Croatian',
    HUNGARIAN = 'Hungarian',
    INDONESIAN = 'Indonesian',
    HEBREW = 'Hebrew',
    HINDI = 'Hindi',
    ICELANDIC = 'Icelandic',
    ITALIAN = 'Italian',
    JAPANESE = 'Japanese',
    SWAHILI = 'Swahili',
    KIRGHIZ_KYRGYZ = 'Kirghiz, Kyrgyz',
    CENTRALKHMER = 'Central Khmer',
    KOREAN = 'Korean',
    KAZAKH = 'Kazakh',
    SINHALA_SINHALESE = 'Sinhala, Sinhalese',
    LITHUANIAN = 'Lithuanian',
    LUXEMBOURGISH_LETZEBURGESCH = 'Luxembourgish, Letzeburgesch',
    LATVIAN = 'Latvian',
    MALAGASY = 'Malagasy',
    BURMESE = 'Burmese',
    MONGOLIAN = 'Mongolian',
    MALTESE = 'Maltese',
    DIVEHI_DHIVEHI_MALDIVIAN = 'Divehi, Dhivehi, Maldivian',
    NORWEGIANBOKMÅL = 'Norwegian Bokmål',
    NEPALI = 'Nepali',
    NAURU = 'Nauru',
    MAORI = 'Maori',
    POLISH = 'Polish',
    ROMANIAN_MOLDAVIAN_MOLDOVAN = 'Romanian, Moldavian, Moldovan',
    SERBIAN = 'Serbian',
    RUSSIAN = 'Russian',
    KINYARWANDA = 'Kinyarwanda',
    SLOVENIAN = 'Slovenian',
    SLOVAK = 'Slovak',
    SOMALI = 'Somali',
    THAI = 'Thai',
    TAJIK = 'Tajik',
    TURKMEN = 'Turkmen',
    TURKISH = 'Turkish',
    UKRAINIAN = 'Ukrainian',
    UZBEK = 'Uzbek',
    VIETNAMESE = 'Vietnamese',
    BISLAMA = 'Bislama',
    SAMOAN = 'Samoan',
    MACEDONIAN = 'Macedonian',
    LAO = 'Lao',
    CZECH = 'Czech',
    LATIN = 'Latin',
  }

  export enum Nationality {
    AF = 'AF',
    AL = 'AL',
    DZ = 'DZ',
    AD = 'AD',
    AO = 'AO',
    AG = 'AG',
    AR = 'AR',
    AM = 'AM',
    AU = 'AU',
    AT = 'AT',
    AZ = 'AZ',
    BS = 'BS',
    BH = 'BH',
    BD = 'BD',
    BB = 'BB',
    BY = 'BY',
    BE = 'BE',
    BZ = 'BZ',
    BJ = 'BJ',
    BM = 'BM',
    BT = 'BT',
    BO = 'BO',
    BA = 'BA',
    BW = 'BW',
    BV = 'BV',
    BR = 'BR',
    IO = 'IO',
    BN = 'BN',
    BG = 'BG',
    BF = 'BF',
    BI = 'BI',
    KH = 'KH',
    CM = 'CM',
    CA = 'CA',
    CV = 'CV',
    KY = 'KY',
    CF = 'CF',
    TD = 'TD',
    CL = 'CL',
    CN = 'CN',
    CX = 'CX',
    CC = 'CC',
    CH = 'CH',
    CO = 'CO',
    KM = 'KM',
    CG = 'CG',
    CD = 'CD',
    CK = 'CK',
    CR = 'CR',
    CI = 'CI',
    HR = 'HR',
    CU = 'CU',
    CY = 'CY',
    CZ = 'CZ',
    DK = 'DK',
    DJ = 'DJ',
    DM = 'DM',
    DO = 'DO',
    EC = 'EC',
    EG = 'EG',
    SV = 'SV',
    GQ = 'GQ',
    ER = 'ER',
    EE = 'EE',
    ET = 'ET',
    FK = 'FK',
    FO = 'FO',
    FJ = 'FJ',
    FI = 'FI',
    FR = 'FR',
    GF = 'GF',
    PF = 'PF',
    TF = 'TF',
    GA = 'GA',
    GM = 'GM',
    GE = 'GE',
    DE = 'DE',
    GH = 'GH',
    GI = 'GI',
    GR = 'GR',
    GL = 'GL',
    GD = 'GD',
    GP = 'GP',
    GU = 'GU',
    GT = 'GT',
    GN = 'GN',
    GW = 'GW',
    GY = 'GY',
    HT = 'HT',
    HM = 'HM',
    VA = 'VA',
    HN = 'HN',
    HK = 'HK',
    HU = 'HU',
    IS = 'IS',
    IN = 'IN',
    ID = 'ID',
    IR = 'IR',
    IQ = 'IQ',
    IE = 'IE',
    IL = 'IL',
    IT = 'IT',
    JM = 'JM',
    JP = 'JP',
    JO = 'JO',
    KZ = 'KZ',
    KE = 'KE',
    KI = 'KI',
    KP = 'KP',
    KR = 'KR',
    KW = 'KW',
    KG = 'KG',
    LA = 'LA',
    LV = 'LV',
    LB = 'LB',
    LS = 'LS',
    LR = 'LR',
    LY = 'LY',
    LI = 'LI',
    LT = 'LT',
    LU = 'LU',
    MO = 'MO',
    MG = 'MG',
    MW = 'MW',
    MY = 'MY',
    MV = 'MV',
    ML = 'ML',
    MT = 'MT',
    MH = 'MH',
    MQ = 'MQ',
    MR = 'MR',
    MU = 'MU',
    YT = 'YT',
    MX = 'MX',
    FM = 'FM',
    MD = 'MD',
    MC = 'MC',
    MN = 'MN',
    MS = 'MS',
    MA = 'MA',
    MZ = 'MZ',
    MM = 'MM',
    NA = 'NA',
    NR = 'NR',
    NP = 'NP',
    NL = 'NL',
    NC = 'NC',
    NZ = 'NZ',
    NI = 'NI',
    NE = 'NE',
    NG = 'NG',
    NU = 'NU',
    NF = 'NF',
    MK = 'MK',
    MP = 'MP',
    NO = 'NO',
    OM = 'OM',
    PK = 'PK',
    PW = 'PW',
    PS = 'PS',
    PA = 'PA',
    PG = 'PG',
    PY = 'PY',
    PE = 'PE',
    PH = 'PH',
    PN = 'PN',
    PL = 'PL',
    PT = 'PT',
    PR = 'PR',
    QA = 'QA',
    RE = 'RE',
    RO = 'RO',
    RU = 'RU',
    RW = 'RW',
    SH = 'SH',
    KN = 'KN',
    LC = 'LC',
    PM = 'PM',
    VC = 'VC',
    WS = 'WS',
    SM = 'SM',
    ST = 'ST',
    SA = 'SA',
    SN = 'SN',
    SC = 'SC',
    SL = 'SL',
    SG = 'SG',
    SK = 'SK',
    SI = 'SI',
    SB = 'SB',
    SO = 'SO',
    ZA = 'ZA',
    GS = 'GS',
    ES = 'ES',
    LK = 'LK',
    SD = 'SD',
    SR = 'SR',
    SJ = 'SJ',
    SZ = 'SZ',
    SE = 'SE',
    SY = 'SY',
    TW = 'TW',
    TJ = 'TJ',
    TZ = 'TZ',
    TH = 'TH',
    TL = 'TL',
    TG = 'TG',
    TK = 'TK',
    TO = 'TO',
    TT = 'TT',
    TN = 'TN',
    TR = 'TR',
    TM = 'TM',
    TC = 'TC',
    TV = 'TV',
    UG = 'UG',
    UA = 'UA',
    AE = 'AE',
    GB = 'GB',
    US = 'US',
    UM = 'UM',
    UY = 'UY',
    UZ = 'UZ',
    VU = 'VU',
    VE = 'VE',
    VN = 'VN',
    VG = 'VG',
    VI = 'VI',
    WF = 'WF',
    EH = 'EH',
    YE = 'YE',
    ZM = 'ZM',
    ZW = 'ZW',
    AX = 'AX',
    BQ = 'BQ',
    CW = 'CW',
    GG = 'GG',
    IM = 'IM',
    JE = 'JE',
    ME = 'ME',
    BL = 'BL',
    MF = 'MF',
    RS = 'RS',
    SX = 'SX',
    SS = 'SS',
    XK = 'XK',
  }

  export enum NationalityEU {
    AT = 'AT',
    BE = 'BE',
    BG = 'BG',
    HR = 'HR',
    CY = 'CY',
    CZ = 'CZ',
    DK = 'DK',
    EE = 'EE',
    FI = 'FI',
    FR = 'FR',
    DE = 'DE',
    GR = 'GR',
    HU = 'HU',
    IE = 'IE',
    IT = 'IT',
    LV = 'LV',
    LT = 'LT',
    LU = 'LU',
    MT = 'MT',
    NL = 'NL',
    PL = 'PL',
    PT = 'PT',
    RO = 'RO',
    SK = 'SK',
    SI = 'SI',
    ES = 'ES',
    SE = 'SE',
    // + Switzerland
    CH = 'CH',
  }

  export function getNationalityFromCode(nationalityCode: Nationality | null | undefined): string {
    if (!nationalityCode) {
      return '';
    }

    switch (nationalityCode) {
      case TranslocoApi.Nationality.AF:
        return 'Afghanistan';
      case TranslocoApi.Nationality.AL:
        return 'Albania';
      case TranslocoApi.Nationality.DZ:
        return 'Algeria';
      case TranslocoApi.Nationality.AD:
        return 'Andorra';
      case TranslocoApi.Nationality.AO:
        return 'Angola';
      case TranslocoApi.Nationality.AG:
        return 'Antigua and Barbuda';
      case TranslocoApi.Nationality.AR:
        return 'Argentina';
      case TranslocoApi.Nationality.AM:
        return 'Armenia';
      case TranslocoApi.Nationality.AU:
        return 'Australia';
      case TranslocoApi.Nationality.AT:
        return 'Austria';
      case TranslocoApi.Nationality.AZ:
        return 'Azerbaijan';
      case TranslocoApi.Nationality.BS:
        return 'Bahamas';
      case TranslocoApi.Nationality.BH:
        return 'Bahrain';
      case TranslocoApi.Nationality.BD:
        return 'Bangladesh';
      case TranslocoApi.Nationality.BB:
        return 'Barbados';
      case TranslocoApi.Nationality.BY:
        return 'Belarus';
      case TranslocoApi.Nationality.BE:
        return 'Belgium';
      case TranslocoApi.Nationality.BZ:
        return 'Belize';
      case TranslocoApi.Nationality.BJ:
        return 'Benin';
      case TranslocoApi.Nationality.BM:
        return 'Bermuda';
      case TranslocoApi.Nationality.BT:
        return 'Bhutan';
      case TranslocoApi.Nationality.BO:
        return 'Bolivia';
      case TranslocoApi.Nationality.BA:
        return 'Bosnia and Herzegovina';
      case TranslocoApi.Nationality.BW:
        return 'Botswana';
      case TranslocoApi.Nationality.BV:
        return 'Bouvet Island';
      case TranslocoApi.Nationality.BR:
        return 'Brazil';
      case TranslocoApi.Nationality.IO:
        return 'British Indian Ocean Territory';
      case TranslocoApi.Nationality.BN:
        return 'Brunei Darussalam';
      case TranslocoApi.Nationality.BG:
        return 'Bulgaria';
      case TranslocoApi.Nationality.BF:
        return 'Burkina Faso';
      case TranslocoApi.Nationality.BI:
        return 'Burundi';
      case TranslocoApi.Nationality.KH:
        return 'Cambodia';
      case TranslocoApi.Nationality.CM:
        return 'Cameroon';
      case TranslocoApi.Nationality.CA:
        return 'Canada';
      case TranslocoApi.Nationality.CV:
        return 'Cape Verde';
      case TranslocoApi.Nationality.KY:
        return 'Cayman Islands';
      case TranslocoApi.Nationality.CF:
        return 'Central African Republic';
      case TranslocoApi.Nationality.TD:
        return 'Chad';
      case TranslocoApi.Nationality.CL:
        return 'Chile';
      case TranslocoApi.Nationality.CN:
        return 'China';
      case TranslocoApi.Nationality.CX:
        return 'Christmas Island';
      case TranslocoApi.Nationality.CC:
        return 'Cocos (Keeling) Islands';
      case TranslocoApi.Nationality.CO:
        return 'Colombia';
      case TranslocoApi.Nationality.KM:
        return 'Comoros';
      case TranslocoApi.Nationality.CG:
        return 'Congo';
      case TranslocoApi.Nationality.CD:
        return 'Congo, Democratic Republic of the';
      case TranslocoApi.Nationality.CK:
        return 'Cook Islands';
      case TranslocoApi.Nationality.CR:
        return 'Costa Rica';
      case TranslocoApi.Nationality.CI:
        return "Côte d'Ivoire";
      case TranslocoApi.Nationality.HR:
        return 'Croatia';
      case TranslocoApi.Nationality.CU:
        return 'Cuba';
      case TranslocoApi.Nationality.CY:
        return 'Cyprus';
      case TranslocoApi.Nationality.CZ:
        return 'Czech Republic';
      case TranslocoApi.Nationality.DK:
        return 'Denmark';
      case TranslocoApi.Nationality.DJ:
        return 'Djibouti';
      case TranslocoApi.Nationality.DM:
        return 'Dominica';
      case TranslocoApi.Nationality.DO:
        return 'Dominican Republic';
      case TranslocoApi.Nationality.EC:
        return 'Ecuador';
      case TranslocoApi.Nationality.EG:
        return 'Egypt';
      case TranslocoApi.Nationality.SV:
        return 'El Salvador';
      case TranslocoApi.Nationality.GQ:
        return 'Equatorial Guinea';
      case TranslocoApi.Nationality.ER:
        return 'Eritrea';
      case TranslocoApi.Nationality.EE:
        return 'Estonia';
      case TranslocoApi.Nationality.ET:
        return 'Ethiopia';
      case TranslocoApi.Nationality.FK:
        return 'Falkland Islands (Malvinas)';
      case TranslocoApi.Nationality.FO:
        return 'Faroe Islands';
      case TranslocoApi.Nationality.FJ:
        return 'Fiji';
      case TranslocoApi.Nationality.FI:
        return 'Finland';
      case TranslocoApi.Nationality.FR:
        return 'France';
      case TranslocoApi.Nationality.GF:
        return 'French Guiana';
      case TranslocoApi.Nationality.PF:
        return 'French Polynesia';
      case TranslocoApi.Nationality.TF:
        return 'French Southern Territories';
      case TranslocoApi.Nationality.GA:
        return 'Gabon';
      case TranslocoApi.Nationality.GM:
        return 'Gambia';
      case TranslocoApi.Nationality.GE:
        return 'Georgia';
      case TranslocoApi.Nationality.DE:
        return 'Germany';
      case TranslocoApi.Nationality.GH:
        return 'Ghana';
      case TranslocoApi.Nationality.GI:
        return 'Gibraltar';
      case TranslocoApi.Nationality.GR:
        return 'Greece';
      case TranslocoApi.Nationality.GL:
        return 'Greenland';
      case TranslocoApi.Nationality.GD:
        return 'Grenada';
      case TranslocoApi.Nationality.GP:
        return 'Guadeloupe';
      case TranslocoApi.Nationality.GU:
        return 'Guam';
      case TranslocoApi.Nationality.GT:
        return 'Guatemala';
      case TranslocoApi.Nationality.GN:
        return 'Guinea';
      case TranslocoApi.Nationality.GW:
        return 'Guinea-Bissau';
      case TranslocoApi.Nationality.GY:
        return 'Guyana';
      case TranslocoApi.Nationality.HT:
        return 'Haiti';
      case TranslocoApi.Nationality.HM:
        return 'Heard Island and McDonald Islands';
      case TranslocoApi.Nationality.VA:
        return 'Holy See (Vatican City State)';
      case TranslocoApi.Nationality.HN:
        return 'Honduras';
      case TranslocoApi.Nationality.HK:
        return 'Hong Kong';
      case TranslocoApi.Nationality.HU:
        return 'Hungary';
      case TranslocoApi.Nationality.IS:
        return 'Iceland';
      case TranslocoApi.Nationality.IN:
        return 'India';
      case TranslocoApi.Nationality.ID:
        return 'Indonesia';
      case TranslocoApi.Nationality.IR:
        return 'Iran, Islamic Republic of';
      case TranslocoApi.Nationality.IQ:
        return 'Iraq';
      case TranslocoApi.Nationality.IE:
        return 'Ireland';
      case TranslocoApi.Nationality.IL:
        return 'Israel';
      case TranslocoApi.Nationality.IT:
        return 'Italy';
      case TranslocoApi.Nationality.JM:
        return 'Jamaica';
      case TranslocoApi.Nationality.JP:
        return 'Japan';
      case TranslocoApi.Nationality.JO:
        return 'Jordan';
      case TranslocoApi.Nationality.KZ:
        return 'Kazakhstan';
      case TranslocoApi.Nationality.KE:
        return 'Kenya';
      case TranslocoApi.Nationality.KI:
        return 'Kiribati';
      case TranslocoApi.Nationality.KP:
        return "Korea, Democratic People's Republic of";
      case TranslocoApi.Nationality.KR:
        return 'Korea, Republic of';
      case TranslocoApi.Nationality.KW:
        return 'Kuwait';
      case TranslocoApi.Nationality.KG:
        return 'Kyrgyzstan';
      case TranslocoApi.Nationality.LA:
        return "Lao People's Democratic Republic";
      case TranslocoApi.Nationality.LV:
        return 'Latvia';
      case TranslocoApi.Nationality.LB:
        return 'Lebanon';
      case TranslocoApi.Nationality.LS:
        return 'Lesotho';
      case TranslocoApi.Nationality.LR:
        return 'Liberia';
      case TranslocoApi.Nationality.LY:
        return 'Libya';
      case TranslocoApi.Nationality.LI:
        return 'Liechtenstein';
      case TranslocoApi.Nationality.LT:
        return 'Lithuania';
      case TranslocoApi.Nationality.LU:
        return 'Luxembourg';
      case TranslocoApi.Nationality.MO:
        return 'Macao';
      case TranslocoApi.Nationality.MG:
        return 'Madagascar';
      case TranslocoApi.Nationality.MW:
        return 'Malawi';
      case TranslocoApi.Nationality.MY:
        return 'Malaysia';
      case TranslocoApi.Nationality.MV:
        return 'Maldives';
      case TranslocoApi.Nationality.ML:
        return 'Mali';
      case TranslocoApi.Nationality.MT:
        return 'Malta';
      case TranslocoApi.Nationality.MH:
        return 'Marshall Islands';
      case TranslocoApi.Nationality.MQ:
        return 'Martinique';
      case TranslocoApi.Nationality.MR:
        return 'Mauritania';
      case TranslocoApi.Nationality.MU:
        return 'Mauritius';
      case TranslocoApi.Nationality.YT:
        return 'Mayotte';
      case TranslocoApi.Nationality.MX:
        return 'Mexico';
      case TranslocoApi.Nationality.FM:
        return 'Micronesia, Federated States of';
      case TranslocoApi.Nationality.MD:
        return 'Moldova, Republic of';
      case TranslocoApi.Nationality.MC:
        return 'Monaco';
      case TranslocoApi.Nationality.MN:
        return 'Mongolia';
      case TranslocoApi.Nationality.MS:
        return 'Montserrat';
      case TranslocoApi.Nationality.MA:
        return 'Morocco';
      case TranslocoApi.Nationality.MZ:
        return 'Mozambique';
      case TranslocoApi.Nationality.MM:
        return 'Myanmar';
      case TranslocoApi.Nationality.NA:
        return 'Namibia';
      case TranslocoApi.Nationality.NR:
        return 'Nauru';
      case TranslocoApi.Nationality.NP:
        return 'Nepal';
      case TranslocoApi.Nationality.NL:
        return 'Netherlands';
      case TranslocoApi.Nationality.NC:
        return 'New Caledonia';
      case TranslocoApi.Nationality.NZ:
        return 'New Zealand';
      case TranslocoApi.Nationality.NI:
        return 'Nicaragua';
      case TranslocoApi.Nationality.NE:
        return 'Niger';
      case TranslocoApi.Nationality.NG:
        return 'Nigeria';
      case TranslocoApi.Nationality.NU:
        return 'Niue';
      case TranslocoApi.Nationality.NF:
        return 'Norfolk Island';
      case TranslocoApi.Nationality.MK:
        return 'North Macedonia';
      case TranslocoApi.Nationality.MP:
        return 'Northern Mariana Islands';
      case TranslocoApi.Nationality.NO:
        return 'Norway';
      case TranslocoApi.Nationality.OM:
        return 'Oman';
      case TranslocoApi.Nationality.PK:
        return 'Pakistan';
      case TranslocoApi.Nationality.PW:
        return 'Palau';
      case TranslocoApi.Nationality.PS:
        return 'Palestinian Territory, Occupied';
      case TranslocoApi.Nationality.PA:
        return 'Panama';
      case TranslocoApi.Nationality.PG:
        return 'Papua New Guinea';
      case TranslocoApi.Nationality.PY:
        return 'Paraguay';
      case TranslocoApi.Nationality.PE:
        return 'Peru';
      case TranslocoApi.Nationality.PH:
        return 'Philippines';
      case TranslocoApi.Nationality.PN:
        return 'Pitcairn';
      case TranslocoApi.Nationality.PL:
        return 'Poland';
      case TranslocoApi.Nationality.PT:
        return 'Portugal';
      case TranslocoApi.Nationality.PR:
        return 'Puerto Rico';
      case TranslocoApi.Nationality.QA:
        return 'Qatar';
      case TranslocoApi.Nationality.RE:
        return 'Réunion';
      case TranslocoApi.Nationality.RO:
        return 'Romania';
      case TranslocoApi.Nationality.RU:
        return 'Russian Federation';
      case TranslocoApi.Nationality.RW:
        return 'Rwanda';
      case TranslocoApi.Nationality.SH:
        return 'Saint Helena, Ascension and Tristan da Cunha';
      case TranslocoApi.Nationality.KN:
        return 'Saint Kitts and Nevis';
      case TranslocoApi.Nationality.LC:
        return 'Saint Lucia';
      case TranslocoApi.Nationality.PM:
        return 'Saint Pierre and Miquelon';
      case TranslocoApi.Nationality.VC:
        return 'Saint Vincent and the Grenadines';
      case TranslocoApi.Nationality.WS:
        return 'Samoa';
      case TranslocoApi.Nationality.SM:
        return 'San Marino';
      case TranslocoApi.Nationality.ST:
        return 'Sao Tome and Principe';
      case TranslocoApi.Nationality.SA:
        return 'Saudi Arabia';
      case TranslocoApi.Nationality.SN:
        return 'Senegal';
      case TranslocoApi.Nationality.SC:
        return 'Seychelles';
      case TranslocoApi.Nationality.SL:
        return 'Sierra Leone';
      case TranslocoApi.Nationality.SG:
        return 'Singapore';
      case TranslocoApi.Nationality.SK:
        return 'Slovakia';
      case TranslocoApi.Nationality.SI:
        return 'Slovenia';
      case TranslocoApi.Nationality.SB:
        return 'Solomon Islands';
      case TranslocoApi.Nationality.SO:
        return 'Somalia';
      case TranslocoApi.Nationality.ZA:
        return 'South Africa';
      case TranslocoApi.Nationality.GS:
        return 'South Georgia and the South Sandwich Islands';
      case TranslocoApi.Nationality.ES:
        return 'Spain';
      case TranslocoApi.Nationality.LK:
        return 'Sri Lanka';
      case TranslocoApi.Nationality.SD:
        return 'Sudan';
      case TranslocoApi.Nationality.SR:
        return 'Suriname';
      case TranslocoApi.Nationality.SJ:
        return 'Svalbard and Jan Mayen';
      case TranslocoApi.Nationality.SZ:
        return 'Eswatini';
      case TranslocoApi.Nationality.SE:
        return 'Sweden';
      case TranslocoApi.Nationality.SY:
        return 'Syrian Arab Republic';
      case TranslocoApi.Nationality.TW:
        return 'Taiwan, Province of China';
      case TranslocoApi.Nationality.TJ:
        return 'Tajikistan';
      case TranslocoApi.Nationality.TZ:
        return 'Tanzania, United Republic of';
      case TranslocoApi.Nationality.TH:
        return 'Thailand';
      case TranslocoApi.Nationality.TL:
        return 'Timor-Leste';
      case TranslocoApi.Nationality.TG:
        return 'Togo';
      case TranslocoApi.Nationality.TK:
        return 'Tokelau';
      case TranslocoApi.Nationality.TO:
        return 'Tonga';
      case TranslocoApi.Nationality.TT:
        return 'Trinidad and Tobago';
      case TranslocoApi.Nationality.TN:
        return 'Tunisia';
      case TranslocoApi.Nationality.TR:
        return 'Turkey';
      case TranslocoApi.Nationality.TM:
        return 'Turkmenistan';
      case TranslocoApi.Nationality.TC:
        return 'Turks and Caicos Islands';
      case TranslocoApi.Nationality.TV:
        return 'Tuvalu';
      case TranslocoApi.Nationality.UG:
        return 'Uganda';
      case TranslocoApi.Nationality.UA:
        return 'Ukraine';
      case TranslocoApi.Nationality.AE:
        return 'United Arab Emirates';
      case TranslocoApi.Nationality.GB:
        return 'United Kingdom';
      case TranslocoApi.Nationality.US:
        return 'United States';
      case TranslocoApi.Nationality.UM:
        return 'United States Minor Outlying Islands';
      case TranslocoApi.Nationality.UY:
        return 'Uruguay';
      case TranslocoApi.Nationality.UZ:
        return 'Uzbekistan';
      case TranslocoApi.Nationality.VU:
        return 'Vanuatu';
      case TranslocoApi.Nationality.VE:
        return 'Venezuela, Bolivarian Republic of';
      case TranslocoApi.Nationality.VN:
        return 'Viet Nam';
      case TranslocoApi.Nationality.VG:
        return 'Virgin Islands, British';
      case TranslocoApi.Nationality.VI:
        return 'Virgin Islands, U.S.';
      case TranslocoApi.Nationality.WF:
        return 'Wallis and Futuna';
      case TranslocoApi.Nationality.EH:
        return 'Western Sahara';
      case TranslocoApi.Nationality.YE:
        return 'Yemen';
      case TranslocoApi.Nationality.ZM:
        return 'Zambia';
      case TranslocoApi.Nationality.ZW:
        return 'Zimbabwe';
      case TranslocoApi.Nationality.AX:
        return 'Åland Islands';
      case TranslocoApi.Nationality.BQ:
        return 'Bonaire, Sint Eustatius and Saba';
      case TranslocoApi.Nationality.CW:
        return 'Curaçao';
      case TranslocoApi.Nationality.GG:
        return 'Guernsey';
      case TranslocoApi.Nationality.IM:
        return 'Isle of Man';
      case TranslocoApi.Nationality.JE:
        return 'Jersey';
      case TranslocoApi.Nationality.MF:
        return 'Saint Martin (French part)';
      case TranslocoApi.Nationality.RS:
        return 'Serbia';
      case TranslocoApi.Nationality.SX:
        return 'Sint Maarten (Dutch part)';
      case TranslocoApi.Nationality.SS:
        return 'South Sudan';
      default:
        return 'Unknown Nationality';
    }
  }

  export function nationalityToTranslocoString(nationality: Nationality): string {
    return `nationalities.${nationality}`;
  }

  export function isEUCitizen(nationality?: Nationality): boolean {
    if (!nationality) {
      return false;
    }

    const euCountries: Partial<Nationality>[] = Object.keys(NationalityEU) as Partial<Nationality>[];

    return euCountries.includes(nationality);
  }

  export function localeToFullValue(locale: TranslocoApi.Locale): string {
    const localeMap: { [key: string]: string } = {
      DE: 'de_DE',
      EN: 'en_US',
      ES: 'es_ES',
      FR: 'fr_FR',
      HR: 'hr_HR',
      IT: 'it_IT',
      PL: 'pl_PL',
      RO: 'ro_RO',
      RU: 'ru_RU',
      TR: 'tr_TR',
      CS: 'cs_CZ',
      SK: 'sk_SK',
      UK: 'uk_UA',
    };

    return localeMap[locale] || 'en_US';
  }
}
