import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LoadingViewModel } from '@web/web/shared/data-access/loading';
import { Observable, catchError, of, takeUntil, throwError } from 'rxjs';
import { finalize } from 'rxjs/operators';

@Injectable()
export class LoadingInterceptor implements HttpInterceptor {
  private totalRequests = 0;

  constructor(private loadingViewModel: LoadingViewModel) {}

  public intercept<T, U>(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<U>> {
    // Fixes bug where loading interceptor breaks heremaps
    // And fixes bug where switching language causes multiple component rerender
    const regex = /hereapi\.com|\/assets\/i18n/;

    if (regex.test(request.url)) {
      return next.handle(request);
    } else {
      this.loadingViewModel.setLoading(true);
      this.totalRequests++;

      return next.handle(request).pipe(
        takeUntil(LoadingViewModel.cancelAllRequests$),
        finalize(() => {
          this.totalRequests--;

          if (this.totalRequests === 0) {
            this.loadingViewModel.setLoading(false);
          }
        }),
        catchError(err => {
          return throwError(err);
        }),
      );
    }
  }
}
