import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { BrandingApi, JobApi } from '@web/shared/data-access/model';
import { PaginateResultWithPageCount } from '@web/shared/util/paginate';
import { Observable } from 'rxjs';
import { BASE_URL_TOKEN } from './config/api-token';

@Injectable({
  providedIn: 'root',
})
export class JobApiService {
  private readonly url: string;
  // TODO: refactor URL usage
  private readonly urlByCompany: string;
  private readonly urlByApplicant: string;

  constructor(
    private readonly httpClient: HttpClient,
    @Inject(BASE_URL_TOKEN) private readonly baseUrl: string,
  ) {
    this.url = `${baseUrl}/jobs`;
    this.urlByCompany = `${baseUrl}/companies`;
    this.urlByApplicant = `${baseUrl}/applicants`;
  }

  public getAllPaginated(
    pageSize: number,
    currentPage: number,
    searchTerm?: string,
  ): Observable<PaginateResultWithPageCount<JobApi.Job>> {
    return this.httpClient.get<PaginateResultWithPageCount<JobApi.Job>>(
      `${this.url}/all${searchTerm ? '?search-term=' + searchTerm : ''}`,
      {
        params: new HttpParams().set('currentPage', currentPage).set('pageSize', pageSize),
      },
    );
  }

  public getMany(companyId: string): Observable<JobApi.Job[]> {
    return this.httpClient.get<JobApi.Job[]>(`${this.urlByCompany}/${companyId}/jobs`);
  }

  public getJobDetails(jobId: string): Observable<JobApi.JobDetails> {
    return this.httpClient.get<JobApi.JobDetails>(`${this.url}/${jobId}`);
  }

  public getJobDetailsOrSuggestionsByIdForExternalApplication(
    jobId: string,
    showShortDescription: boolean,
  ): Observable<JobApi.JobDetails[]> {
    return this.httpClient.get<JobApi.JobDetails[]>(
      `${this.url}/${jobId}/for-external-application?shortDescription=${showShortDescription}`,
    );
  }

  public create(job: JobApi.CreateJob): Observable<JobApi.Job> {
    return this.httpClient.post<JobApi.Job>(`${this.url}`, job);
  }

  public addJobBrandingImages(imageFiles: BrandingApi.Image[], brandingId: string): Observable<void> {
    const formData = new FormData();

    imageFiles.forEach(file => {
      formData.append('files', <File>file.image, <string>file.image?.name);
      formData.append('createImgDto', JSON.stringify({ isHeader: file.isHeader, name: <string>file.image?.name }));
    });

    return this.httpClient.post<void>(`${this.url}/branding-images/${brandingId}`, formData);
  }

  public deleteBrandingImage(id: string): Observable<void> {
    return this.httpClient.delete<void>(`${this.url}/branding-image/${id}`);
  }

  public toggleHeaderImage(image: BrandingApi.Image): Observable<void> {
    return this.httpClient.put<void>(`${this.url}/branding-image/${image.id}`, image);
  }

  public edit(job: JobApi.UpdateJob): Observable<JobApi.Job> {
    return this.httpClient.put<JobApi.Job>(`${this.url}`, job);
  }

  public getJobsAdmin(
    pageSize: number,
    currentPage: number,
    searchTerm?: string,
  ): Observable<PaginateResultWithPageCount<JobApi.JobAdmin>> {
    return this.httpClient.get<PaginateResultWithPageCount<JobApi.JobAdmin>>(
      `${this.url}${searchTerm ? '?search-term=' + searchTerm : ''}`,
      {
        params: new HttpParams().set('currentPage', currentPage).set('pageSize', pageSize),
      },
    );
  }

  public createJobAdmin(job: JobApi.CreateJobAdmin): Observable<JobApi.Job> {
    return this.httpClient.post<JobApi.Job>(`${this.url}/admin`, job);
  }

  public duplicateJob(jobId: string | null): Observable<string> {
    return this.httpClient.post(`${this.url}/${jobId}/duplicate`, {}, { responseType: 'text' });
  }

  public deleteJobAdmin(jobId: string | null): Observable<void> {
    return this.httpClient.delete<void>(`${this.url}/${jobId}`);
  }

  public updateGeneralJobInformation(job: JobApi.UpdateGeneralJobData): Observable<JobApi.JobDetailGeneral> {
    return this.httpClient.put<JobApi.JobDetailGeneral>(`${this.url}/${job.id}/general`, job);
  }

  public updateRecruitmentProcessJobInformation(
    jobId: string,
    recruitmentProcessJobData: JobApi.UpdateRecruitmentProcessJobData,
  ): Observable<JobApi.JobDetailRecruitmentProcessWrapper> {
    return this.httpClient.put<JobApi.JobDetailRecruitmentProcessWrapper>(
      `${this.url}/${jobId}/recruitment-process`,
      recruitmentProcessJobData,
    );
  }

  public getAllByJobType(jobTypeId: string, applicantId: string): Observable<JobApi.JobDetailDropdown[]> {
    return this.httpClient.get<JobApi.JobDetailDropdown[]>(
      `${this.url}/job-type/${jobTypeId}/applicant/${applicantId}`,
    );
  }

  public filterJobs(
    jobFilter: Partial<JobApi.JobFilter>,
    pageSize: number,
    currentPage: number,
    searchTerm?: string,
  ): Observable<PaginateResultWithPageCount<JobApi.JobAdmin>> {
    return this.httpClient.post<PaginateResultWithPageCount<JobApi.JobAdmin>>(
      `${this.url}/admin/filter${searchTerm ? '?search-term=' + searchTerm : ''}`,
      {
        jobTypes: jobFilter.jobTypes?.map(j => j.id),
        professionalFields: jobFilter.professionalFields?.map(pf => pf.id),
        occupationalGroups: jobFilter.occupationalGroups?.map(o => o.id),
        occupationalSubGroups: jobFilter.occupationalSubGroups?.map(os => os.id),
        jobTypeCategories: jobFilter.jobTypeCategories?.map(jtc => jtc.id),
        status: jobFilter.status,
        distance: jobFilter.distance,
        address: jobFilter.address,
      },
      {
        params: new HttpParams().set('currentPage', currentPage).set('pageSize', pageSize),
      },
    );
  }

  public filterJobsEmployer(jobFilter: Partial<JobApi.JobFilter>, companyId: string): Observable<JobApi.Job[]> {
    return this.httpClient.post<JobApi.Job[]>(`${this.urlByCompany}/${companyId}/jobs/filter`, {
      status: jobFilter.status,
    });
  }

  public findByQuery(searchTerm: string): Observable<JobApi.JobDetailDropdown[]> {
    return this.httpClient.get<JobApi.JobDetailDropdown[]>(`${this.url}/search`, {
      params: { searchTerm },
    });
  }

  public findByQueryForApplicant(applicantId: string, searchTerm: string): Observable<JobApi.JobDetailDropdown[]> {
    return this.httpClient.get<JobApi.JobDetailDropdown[]>(`${this.urlByApplicant}/${applicantId}/jobs/search`, {
      params: { searchTerm },
    });
  }

  public updateJobStatus(id: string | null): Observable<void> {
    return this.httpClient.put<void>(`${this.url}/${id}/archive`, {});
  }

  public getJobDetailGeneral(jobId: string): Observable<JobApi.JobDetailGeneral> {
    return this.httpClient.get<JobApi.JobDetailGeneral>(`${this.url}/${jobId}/general`);
  }

  public getJobDetailBranding(jobId: string): Observable<JobApi.JobDetailBranding> {
    return this.httpClient.get<JobApi.JobDetailBranding>(`${this.url}/${jobId}/branding`);
  }

  public getJobDetailRecruitmentProcess(jobId: string): Observable<JobApi.JobDetailRecruitmentProcessWrapper> {
    return this.httpClient.get<JobApi.JobDetailRecruitmentProcessWrapper>(`${this.url}/${jobId}/recruitment-process`);
  }

  public updateBranding(brandingDto: JobApi.JobDetailBrandingUpdate): Observable<JobApi.JobDetailBranding> {
    const formData = new FormData();

    formData.append('id', brandingDto.id);

    formData.append('jobId', brandingDto.jobId);

    if (brandingDto.shortDescription) {
      formData.append('shortDescription', JSON.stringify(brandingDto.shortDescription));
    }

    if (brandingDto.description) {
      formData.append('description', JSON.stringify(brandingDto.description));
    }

    if (brandingDto.videoLink) {
      formData.append('videoLink', brandingDto.videoLink);
    }

    if (brandingDto.newImages?.length) {
      brandingDto.newImages.forEach(image => {
        formData.append('newImages', JSON.stringify({ isHeader: image.isHeader, name: image.name }));
        formData.append('files', image.file, image.name);
      });
    }

    if (brandingDto.updateImages?.length) {
      formData.append('updateImages', JSON.stringify(brandingDto.updateImages));
    }

    if (brandingDto.idOfImagesToDelete?.length) {
      formData.append('idOfImagesToDelete', JSON.stringify(brandingDto.idOfImagesToDelete));
    }

    return this.httpClient.put<JobApi.JobDetailBranding>(`${this.url}/${brandingDto.id}/branding`, formData);
  }

  public reSyncToJobBoard(jobId: string): Observable<void> {
    return this.httpClient.post<void>(`${this.url}/${jobId}/job-board`, {});
  }

  public syncAllJobsToTheJobBoard(): Observable<void> {
    return this.httpClient.post<void>(`${this.url}/job-board/sync-all`, {});
  }
}
