// eslint-disable-next-line @nx/enforce-module-boundaries
import { TranslocoApi } from '@web/shared/data-access/model';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { DropdownOption } from '@web/web/shared/ui/dropdown';

export const SUPPORTED_LANGUAGES: string[] = [
  TranslocoApi.Locale.EN.toLowerCase(),
  TranslocoApi.Locale.DE.toLowerCase(),
  TranslocoApi.Locale.HR.toLowerCase(),
  TranslocoApi.Locale.RO.toLowerCase(),
  TranslocoApi.Locale.ES.toLowerCase(),
  TranslocoApi.Locale.PL.toLowerCase(),
  TranslocoApi.Locale.TR.toLowerCase(),
  TranslocoApi.Locale.FR.toLowerCase(),
  TranslocoApi.Locale.IT.toLowerCase(),
  TranslocoApi.Locale.RU.toLowerCase(),
  TranslocoApi.Locale.CS.toLowerCase(),
  TranslocoApi.Locale.UK.toLowerCase(),
  TranslocoApi.Locale.SK.toLowerCase(),
];

export const DEFAULT_LANG_AS_STRING = TranslocoApi.Locale.EN.toLowerCase();

export const DEFAULT_LANG_AS_LOCALE = TranslocoApi.Locale.EN;

export const DEFAULT_LANG_DROPDOWN_OPTION: DropdownOption = {
  label: `${TranslocoApi.getEmoji(TranslocoApi.Locale.EN)} ${TranslocoApi.getLanguageString(TranslocoApi.Locale.EN)}`,
  value: TranslocoApi.Locale.EN,
};
